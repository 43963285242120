import React from 'react'
import bull from '../../../Images/My FPrec Competition bull blue-01.png'
const SupportMidFour = () => {
  return (
    <div class="text-center my-2  py-6   ">
 {/*} <h1 class="text-black text-4xl font-bold">Funded Forex Account Bonuses!</h1> */}
  <img class="w-full max-w-lg mx-auto mb-4 " src={bull} alt="" />
 {/*} <p class="w-full  text-blue-950">
  Our bonuses reflect our aspiration to attract, motivate and retain talented traders. Are you ready to start your trading journey with MyForexFunds family? Bring your skills and build your future with us through a variety of rewards.
  </p> */}
</div>

  )
}

export default SupportMidFour