import React from "react";
function Awards() {
  return (
    <div className="awards">
      <div className="award-wrapper">
        <ul className="awards-ul">
          <li className="a-list"></li>
          <li className="a-list"></li>
          <li className="a-list"></li>
          <li className="a-list"></li>
          <li className="a-list"></li>
          <li className="a-list"></li>
        </ul>
      </div>
    </div>
  );
}
export default Awards
