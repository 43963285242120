import React from "react";

import { useNavigate } from "react-router-dom";

const Cancel = () => {
  const navigate = useNavigate();
  return (
    <div>
Cancel
    </div>
  );
};

export default Cancel;
