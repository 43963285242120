import React from "react";

import { useNavigate } from "react-router-dom";

const Success = () => {
  const navigate = useNavigate();
  return (
    <div>
success
    </div>
  );
};

export default Success;
